/* eslint-disable */

/*
account_code: ""
activity: ""
amount_erd: ""
centre: ""
code: ""
coefficient: ""
contract: ""
cost: 152
discount_percentage: ""
eid: ""
expiration_date: "2022-05-27"
external_id: ""
guarantee: ""
guarantee_type: ""
id: 3
moss_type: ""
note: "druhy item ponz"
pdp_code: ""
price_final: "123.00"
price_original: ""
price_vat: ""
price_unit: ""
quantity: ""
rate_erd: ""
reverse_charge: false
stock_item: ""
stock_link: ""
sympar: ""
tax_rate: 2
text: "Tohle je druhy item text"
unit: ""
vat_classification: ""
vat_kv_classification: ""
voucher_eet: ""
*/

import styles from 'css_modules/ItemRow.module.css';
import {FieldArray, useFormikContext} from 'formik';
import {ItemCell} from 'components/ItemCell';
//import {ReactComponent as CancelIcon} from 'assets/svg/cancel.svg';
import {Select} from 'components/Select';
import React, {useEffect,useRef,useState } from 'react';
import { AutocompleteSelect } from './AutocompleteSelect';

function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    }, [value]);
    return ref.current;
  }

  

export const ItemRow = ({index, item, editable, taxRates,filteredCentres,filteredActivities,
    filteredContracts,filteredVatClassification,filteredVatKvClassification,
    filteredAccountCode,doctype,checkedItems,setCheckedItems,setAllItemsChecked,bulkEdit}) => {
    
    const [refresh,setRefresh] = useState(true)
    
    const {handleChange, setFieldValue, values, resetForm} = useFormikContext();
    const prevItem = usePrevious(values.items[index]);
    //const amount_erd_name = `items.${index}.amount_erd`;
    //const eid_name = `items.${index}.eid`;
    //const expiration_date_name = `items.${index}.expiration_date`;
    //const external_id_name = `items.${index}.external_id`;
    //const guarantee_name = `items.${index}.guarantee`;
    //const guarantee_type_name = `items.${index}.guarantee_type`;
    //const id_name = `items.${index}.id`;
    //const moss_type_name = `items.${index}.moss_type`;
    //const pdp_code_name = `items.${index}.pdp_code`;
    //const rate_erd_name = `items${index}.rate_erd`;
    //const stock_item_name_name = `items.${index}.stock_link`;
    //const voucher_eet_name = `items.${index}.voucher_eet`;
     
    const id_name = `items.${index}.id`;
    const account_code_name = `items.${index}.account_code`;
    const activity_name = `items.${index}.activity`;
    const centre_name = `items.${index}.centre`;
    const code_name = `items.${index}.code`;
    const coefficient_name = `items.${index}.price_unit`;
    const contract_name = `items.${index}.contract`;
    const cost_name = `items.${index}.cost`;
    const discount_percentage_name = `items.${index}.discount_percentage`;
    const note_name = `items.${index}.note`;
    const price_final_name= `items.${index}.price_final`;
    const price_original_name = `items.${index}.price_original`;
    const price_vat_name = `items.${index}.price_vat`;
    const quantity_name = `items.${index}.quantity`;
    const reverse_charge_name = `items.${index}.reverse_charge`;
    const sympar_name = `items.${index}.sympar`;
    const tax_rate_name = `items.${index}.tax_rate`;
    const text_name = `items.${index}.text`;
    const unit_name = `items.${index}.unit`;
    const vat_classification_name = `items.${index}.vat_classification`;
    const vat_kv_classification_name = `items.${index}.vat_kv_classification`;
    

    let displayedRates = taxRates
    
    let showUnknownRate = false
    for (let i = 0; i<values?.costs?.length; i++){
        let c = values.costs[i]

        if (bulkEdit===false){
            if ( String(displayedRates?.find(rate => rate.id === parseInt(c.tax_rate))?.slug).endsWith('cz_unknown')  ){
                showUnknownRate = true
            }
            if ( String(displayedRates?.find(rate => rate.id === parseInt(c.tax_rate))?.slug).endsWith('sk_unknown')  ){
                showUnknownRate = true
            }
        }
        
    }
    
    
    
    if (!showUnknownRate){
        let rateToRemove = displayedRates?.find(rate => rate.slug === 'cz_unknown')
        if (rateToRemove){
            displayedRates.splice(displayedRates.indexOf(rateToRemove), 1);
        }
        rateToRemove = displayedRates?.find(rate => rate.slug === 'sk_unknown')
        if (rateToRemove){
            displayedRates.splice(displayedRates.indexOf(rateToRemove), 1);
        }
    }
    
    const calculateTax = (priceOriginal) => {
        const taxRate =  taxRates?.find(rate => rate.id === parseInt(values.items[index].tax_rate))?.rate;  
        let tax_val =  priceOriginal  * (taxRate )/100.0 
        let price_final_new = parseFloat(priceOriginal) +parseFloat(tax_val) 

        if (!values.items[index].helperItem){
            setFieldValue(price_vat_name, tax_val.toFixed(2));
            setFieldValue(price_final_name, price_final_new.toFixed(2));
        }
    }
    
    let reverse_charge_disabled = true
    if (doctype==='fvy' || doctype==='fvz' || doctype==='opo'){
        reverse_charge_disabled = false
    }

    const handleOriginalValueChange = (e) => {
        handleChange(e);
        
        //setFieldValue(price_original_name, parseFloat(e.target.value).toFixed(2));
        if (values.items[index].helperItem){
            return
        }

        calculateTax(e.target.value);

        if (values.items[index].quantity){
            let newcoef = e.target.value / parseFloat(values.items[index].quantity) * (1+values.items[index].discount_percentage/100)
            setFieldValue(coefficient_name, newcoef );
        }
        else{
            let newcoef = e.target.value 
            setFieldValue(coefficient_name, newcoef );
            //setFieldValue(quantity_name, values.items[index].quantity);
        }
    };

    const handleSelectChange = (e) =>{
        handleChange(e)
        let chckd = sessionStorage.getItem("checkedItems") ? JSON.parse(sessionStorage.getItem("checkedItems")) : []
        if (chckd.length >0 && sessionStorage.getItem("itemsBulkModify")==="true"){
            const currentItem = values.items[index];
            let changedKeys = []
            if (prevItem) {
                for (const key in currentItem) {
                    if (currentItem[key] !== prevItem[key]) {
                    ////console.log(`Item ${index} property ${key} changed from ${prevItem[key]} to ${currentItem[key]}`);
                    changedKeys.push( [key,currentItem[key]]  )
                    }
                }
            }
            if (currentItem.helperItem === true) {
                if (changedKeys.length>0){
                    ////console.log(changedKeys)
                    const updatedItems = [...values.items];
                    updatedItems.forEach(i => {
                        let idx = updatedItems.indexOf(i)
                        let key = changedKeys[0][0]
                        let val = changedKeys[0][1]
                        if (chckd.includes(idx) && i.helperItem !== true){
                            ////console.log("Updating item " + idx + " key " + key + " to " + val  )
                            if (["centre","activity","contract","vat_classification","vat_kv_classification","account_code"].includes(key) && val == -2){}
                            else{
                                values.items[idx][key] = val
                                setFieldValue(`items.${idx}.centre`,val)
                                //console.log(key + " on item  " + idx +" set to " +values.items[idx][key] )
                            }

                            updateItem(idx,key)
                        }})
                }
            }
        }
    }   
     
    const calculateOriginalValue = (q, c,discount) => {
        if (q===null) q = values.items[index].quantity;
        if (c===null) c = values.items[index].price_unit;
        if (!discount ||discount===null) {
            let disc = parseFloat(values.items[index].discount_percentage)
            discount = disc ? disc : 0 
        }
   
        setFieldValue(quantity_name, q);
        if (c===''){c = 0;}
                
        const newPrice = q * c *(1-discount/100);
        //setFieldValue(price_original_name, parseFloat(newPrice).toFixed(2))
        ////console.log("Calculated original price ",newPrice)
        return newPrice.toFixed(2);
    }

    const handleQuantityChange = (e) => {
        //console.log("Quantity change")
        handleChange(e);
        if (values.items[index].helperItem){return}        
        values.items[index].price_original = parseFloat(values.items[index].price_unit * e.target.value).toFixed(2)
        calculateTax(parseFloat(values.items[index].price_original).toFixed(2));
    }


    const handleCoefficientChange = (e) => {
        //console.log("Coef change")
        handleChange(e);
        if (values.items[index].helperItem){
            setFieldValue(coefficient_name, e.target.value);
            return
        }
        let priceOriginal = calculateOriginalValue(null, e.target.value);
        //console.log("ret ",priceOriginal)
        setFieldValue(price_original_name, priceOriginal);
        calculateTax(priceOriginal);
    }

    const handleTaxRateChange = (e) => {
        //handleChange(e);
        if (values.items[index].helperItem){
            setFieldValue(tax_rate_name, e);
            return
        }
        assignCost(e);        
        
        values.items[index].tax_rate = e
        calculateTax(values.items[index].price_original);
    }

   
    const handleDiscountChange = (e) => {
        handleChange(e);
        
        if (values.items[index].helperItem){
            setFieldValue(discount_percentage_name, e.target.value);
            return
        }
        let priceOriginal = calculateOriginalValue(null, null,e.target.value);
        calculateTax( priceOriginal );
    }
  
    const emptyCost = (taxRateId) => {
        return {
            tax_rate: taxRateId,
            value_original: '',
            tax: '',
            value_final: ''
        };
    }

    let assignedCostIndex = 0;
    const assignCost = (currentTaxRate) => {
        if (!values.items[index].helperItem){
            for (let i=0; i <values.costs.length; i++){
                if (parseInt(values.costs[i].tax_rate) === parseInt(currentTaxRate) ){
                    setFieldValue(cost_name, values.costs[i].id);
                    assignedCostIndex = i;
                    values.items[index].cost = i
                    return;
                }
            }
            values.costs.push(emptyCost(parseInt(currentTaxRate)))
            //if (values.items[index].helperItem){
            //    setFieldValue(cost_name, undefined)//values.costs[values.costs.length-1].id);
            //}
            values.items[index].cost = undefined
        }
    }

    useEffect( () => { 
        if (values.items[index].tax_rate!==''){
            ////console.log("item " + index + " cost changed to " + values.items[index].cost)
        } 
    },[values.items[index].cost])

    useEffect( () => { 
        if (values.items[index].tax_rate!==''){
            assignCost(values.items[index].tax_rate) 
            sumCostsFromItems()
        } 
    },[values.items[index].tax_rate])

    

    const sumCostsFromItems = () => {
        values.costs.forEach(c => {
            let sumOriginal = 0;
            let sumTax = 0;
            values.items?.filter( item => parseInt(item.tax_rate) === parseInt(c.tax_rate)).forEach(item=> {
                if (String(item.price_original)!=='' && String(item.price_vat)!==''){
                    sumOriginal += parseFloat(item.price_original)
                    sumTax += parseFloat(item.price_vat)
                }
                
            } )
            sumOriginal = parseFloat(sumOriginal)
            sumTax = parseFloat(sumTax)
    
            if (c.value_original !== sumOriginal){
                c.value_original = sumOriginal.toFixed(2);
                c.tax = sumTax.toFixed(2)
                c.value_final = (sumOriginal + sumTax).toFixed(2)
            }
        })
    }
    function deleteEmptyCosts (){
        let nonEmptyCosts = values.costs.filter(c => {
            let assignedItems = 0;
            values.items.forEach(i => {
                if (parseInt(i.tax_rate) === parseInt(c.tax_rate) ) {
                    assignedItems += 1;
                }
            });
            return assignedItems > 0;
        });
        values.costs = nonEmptyCosts
    }
    const pF = (val) => {
        const parsed = parseFloat(val);
        return isNaN(parsed) ? 0 : parsed;
    }
    const updateItem = (idx,key) =>{
        //console.log("updating item nr " , idx, key)
        let itm = values.items[idx]
        let taxRate =  taxRates?.find(rate => rate.id === parseInt(itm["tax_rate"]))?.rate;  

        if ( ["tax_rate"].includes(key)){
            for (let i=0; i <values.costs.length; i++){
                if (parseInt(values.costs[i].tax_rate) === parseInt(taxRate) ){
                    itm["cost"] = values.costs[i].id;
                    assignedCostIndex = i;
                    return;
                }
            }
            if (!values.costs.filter(i=> i.tax_rate === taxRate)){
                values.costs.push(emptyCost(parseInt(currentTaxRate)))
                itm["cost"] = undefined
            }
        }

        if ( ["refresh"].includes(key)){
            itm["price_original"] = itm["price_original"]
        }
        if ( ["quantity", "price_unit","discount_percentage","tax_rate"].includes(key)){
            itm["price_original"] = pF(pF(itm["price_unit"]) * pF(itm["quantity"]) * (1-itm["discount_percentage"]/100)).toFixed(2)
            itm["price_vat"]      = pF(pF(itm["price_original"])  * pF(taxRate)/100.0 ).toFixed(2)
            itm["price_final"]    = pF(pF(itm["price_original"]) + itm["price_vat"]).toFixed(2)
        }
        if ( ["price_original"].includes(key)){
            itm["price_unit"]     = pF(pF(itm["price_original"]) / pF(itm["quantity"]) * (1+itm["discount_percentage"]/100)).toFixed(2)
            itm["price_vat"]      = pF(pF(itm["price_original"])  * pF(taxRate)/100.0).toFixed(2)
            itm["price_final"]    = pF(pF(itm["price_original"]) + itm["price_vat"]).toFixed(2)
        }

        let b = itm["price_final"]
        itm["price_final"] = 0.1
        itm["price_final"] = b
        
        assignCost(taxRate)
        sumCostsFromItems()
        deleteEmptyCosts()
        
    }

    useEffect(() => {
        let chckd = sessionStorage.getItem("checkedItems") ? JSON.parse(sessionStorage.getItem("checkedItems")) : []
        
        if (chckd.length >0 && sessionStorage.getItem("itemsBulkModify")==="true"){
            const currentItem = values.items[index];
            let changedKeys = []
            if (prevItem) {
                for (const key in currentItem) {
                    if (currentItem[key] !== prevItem[key]) {
                    ////console.log(`Item ${index} property ${key} changed from ${prevItem[key]} to ${currentItem[key]}`);
                    changedKeys.push( [key,currentItem[key]]  )
                    }
                }
            }
            if (currentItem.helperItem === true) {
                if (changedKeys.length>0){
                    const updatedItems = [...values.items];
                    updatedItems.forEach(i => {
                        let idx = updatedItems.indexOf(i)
                        let key = changedKeys[0][0]
                        let val = changedKeys[0][1]
                        if (chckd.includes(idx) && i.helperItem !== true){
                            ////console.log("Updating item " + idx + " key " + key + " to " + val  )
                            if (["centre","activity","contract","vat_classification","vat_kv_classification","account_code"].includes(key) && val == -2){
                                
                            }
                            else{
                                values.items[idx][key] = val
                                setFieldValue(values.items[idx][key] , val)
                               // //console.log(key + " on item  " + idx +" set to " +values.items[idx][key] )
                            }

                            updateItem(idx,key)
                        }})
                }
           // resetForm(values);
            }
        }
        else{
            values.items.forEach(i => {updateItem(values.items.indexOf(i),"refresh")})
        }
      }, [values.items[index]]);

    const handleCheckbox = () => {
            let newCheckedItems = JSON.parse(JSON.stringify(checkedItems))
            if (newCheckedItems.includes(index)) {
                newCheckedItems.splice(checkedItems.indexOf(index), 1);
            } else {
                newCheckedItems.push(index);
            }
        setCheckedItems(newCheckedItems)
        setAllItemsChecked(false);
    };

   
    const updateValueFromHelperItem =  (key,value) => {
        let chckd = sessionStorage.getItem("checkedItems") ? JSON.parse(sessionStorage.getItem("checkedItems")) : []

        console.log(key, value)
        if (parseInt(value) !== parseInt(-2)){
            values.items.forEach(i=>{
                if (chckd.includes(values.items.indexOf(i))){
                    i[key] = value
                    setFieldValue(values.items[values.items.indexOf(i)][key],value)
                }
            })
        }
        else{
            let backupItems = JSON.parse(sessionStorage.getItem("backupItems"))
            values.items.forEach((i,idx)=>{
                if (idx<backupItems.length-1){
                    let val = backupItems[idx][key] 
                    i[key] = val
                    setFieldValue(values.items[idx][key],val)
                }   
            })
            setFieldValue(values.items[values.items.length-1][key],-2)
            values.items[values.items.length-1][key]=-2
            }


        console.log(values.items)
    }

    const centreChange = (e) => {
        if (values.items[index].helperItem === true) {
          updateValueFromHelperItem("centre", e);
        }
        else{
            values.items[index].centre = e
            setFieldValue(values.items[index]["centre"],e)
        }           
      };
      
      const activityChange = (e) => {
        if (values.items[index].helperItem === true) {
            updateValueFromHelperItem("activity", e);
        } else {
            values.items[index].activity = e;
            setFieldValue(values.items[index]["activity"], e);
        }
    };
    
    const contractChange = (e) => {
        if (values.items[index].helperItem === true) {
            updateValueFromHelperItem("contract", e);
        } else {
            values.items[index].contract = e;
            setFieldValue(values.items[index]["contract"], e);
        }
    };
    
    const vatClassificationChange = (e) => {
        if (values.items[index].helperItem === true) {
            updateValueFromHelperItem("vat_classification", e);
        } else {
            values.items[index].vat_classification = e;
            setFieldValue(values.items[index]["vat_classification"], e);
        }
    };
    
    const vatKvClassificationChange = (e) => {
        if (values.items[index].helperItem === true) {
            updateValueFromHelperItem("vat_kv_classification", e);
        } else {
            values.items[index].vat_kv_classification = e;
            setFieldValue(values.items[index]["vat_kv_classification"], e);
        }
    };
    
    const accountCodeChange = (e) => {
        if (values.items[index].helperItem === true) {
            updateValueFromHelperItem("account_code", e);
        } else {
            values.items[index].account_code = e;
            setFieldValue(values.items[index]["account_code"], e);
        }
    };
    
      /*
    useEffect( () => { 
        let chckd = sessionStorage.getItem("checkedItems") ? JSON.parse(sessionStorage.getItem("checkedItems")) : []
        if (values.items[index].helperItem === true && chckd.length >0 && sessionStorage.getItem("itemsBulkModify")==="true"){
            let newVals = JSON.parse(JSON.stringify(values))
            resetForm(newVals);
        }
    },[values.items[index].centre,values.items[index].activity,
        values.items[index].contract,values.items[index].account_code,
        values.items[index].vat_classification,values.items[index].vat_kv_classification 
        ])*/

        const handleKeyDown = (event) => {
            let name = event.target.name.split(".")[2]
            
            if (values.items[index].helperItem === true ){
                let chckd = sessionStorage.getItem("checkedItems") ? JSON.parse(sessionStorage.getItem("checkedItems")) : []
                if (values.items[index][name] === '' && (event.key === 'Backspace' || event.key === 'Delete')) {
                    
                    event.preventDefault(); 
                    values.items[index][name] = ''
                    setFieldValue(values.items[index][name], '');

                    values.items.forEach((i,idx)=>{
                        if (chckd.includes(idx)){
                            i[name] = ''
                            setFieldValue(values.items[idx][name], '');
                        }
                    })
                        
                }           
            }
            
          };

    return (
        <>
        <div className={styles.rowwrapper}>

        <FieldArray name="items">
        {arrayHelpers => (
        <table className={styles.itemstable}>
        <tbody>
        <tr>
        <td className={styles.itemstd}>
        {!bulkEdit ? index + 1 : ""}
        </td>
        <td className={styles.acrosstwocolumns}>
            <td className={styles.nestedcell1}>
                    <ItemCell
                    name={code_name}
                    type="text"
                    //placeholder="Kód"
                    disabled={!editable}
                    onKeyDown={handleKeyDown}
                >
                </ItemCell>
            </td>
            <td className={styles.nestedcell2}>
                <ItemCell
                    name={text_name}
                    type="text"
                    placeholder={values.items[index].helperItem === true ? "" : "Název položky"}
                    onKeyDown={handleKeyDown}
                    disabled={!editable}
                >
                </ItemCell>
            </td>

        </td>

        <td className={styles.itemstd} >
                 <ItemCell
                    name={quantity_name}
                    type="number"
                    step="0.0001"
                    onChange={handleQuantityChange}
                    disabled={!editable}  >
                    
                </ItemCell>
                </td>
        <td className={styles.itemstd}>
             <ItemCell
                     name={unit_name}      
                     disabled={!editable}
                     onKeyDown={handleKeyDown}         
                >
               
                </ItemCell>
                </td>
        <td className={styles.itemstd}>
                <ItemCell
                    name={coefficient_name}
                    type="number"
                    step="0.01"
                    onChange={handleCoefficientChange}
                    disabled={!editable}
                    
                >
                  
                </ItemCell>
                </td>
        <td className={styles.itemstd1}>
            <AutocompleteSelect 
                    name = {tax_rate_name}
                    data={displayedRates}
                    itemRow={true}
                    helperItem={values.items[index].helperItem}
                    notNull={true}
                    noBlankOption={true}
                    disabled={!editable}
                    selectedValue={values.items[index]["tax_rate"]} 
                        onChange={(newValue) => {
                            values.items[index]["tax_rate"]
                            handleTaxRateChange(newValue)
                            setRefresh(!refresh)
                        }} 
                >
            </AutocompleteSelect>
        </td>
        <td className={styles.itemstd}>
                <ItemCell
                    name={discount_percentage_name}
                    type="number"
                    step="0.01"
                    min="0"
                    onChange={handleDiscountChange}
                    disabled={!editable}
                >
                
                </ItemCell>
        </td>
        <td className={styles.itemstd}>
        <ItemCell
                    name={price_original_name}
                    type="number"
                    step="0.01"
                    placeholder={values.items[index].helperItem === true ? "" : "Základ bez DPH"}
                    onChange={ handleOriginalValueChange}
                    disabled={!editable}
                   
                >
                  
                </ItemCell>
        </td>
        <td className={styles.itemstd}>
        <ItemCell
                    name={price_vat_name}
                    type="number"
                    step="0.01"
                    min="0"
                    placeholder={values.items[index].helperItem === true ? "" : "Daň"}
                    disabled={!editable}
                >
                  
                </ItemCell>
        </td>
        </tr>
        
        <tr>
        { (editable===true && !bulkEdit)? 
            <td className={styles.checkcell}>
                 <ItemCell type="checkbox" name={"check"}   style={{ padding:'none',margin:'none', border: 'none', outline: 'none' }}
                 disabled={!editable} checked={checkedItems.includes(index)} onChange={handleCheckbox}
                 />
            </td>
            :<td className={styles.itemstd}></td>
        }
        
        <td className={styles.itemstd}>
        <ItemCell
                    name={note_name}
                    type="text"
                    disabled={!editable}
                    onKeyDown={handleKeyDown}
                    >
                   
                </ItemCell>
        </td>
        <td className={styles.itemstd}>
                <ItemCell
                    name={sympar_name}
                    type="text"
                    disabled={!editable}
                    onKeyDown={handleKeyDown}
                >
                   
                </ItemCell>
        </td>
        <td className={styles.itemstd1}>
            <AutocompleteSelect 
                    name={account_code_name}
                    emptyOption={values.items[index].helperItem===true}
                    helperItem={values.items[index].helperItem}
                    data={filteredAccountCode}
                    disabled={!editable}
                    itemRow={true}
                    notNull={false}
                    selectedValue={values.items[index]["account_code"]} 
                    onChange={(newValue) => {
                        values.items[index]["account_code"]
                        accountCodeChange(newValue)
                        setRefresh(!refresh)
                    }} 
                >
                    
                </AutocompleteSelect>
        </td>
        <td className={styles.itemstd}>
        <ItemCell type="checkbox" name={reverse_charge_name}  style={{ padding:'none',margin:'none', border: 'none', outline: 'none' }}
            disabled={reverse_charge_disabled}
            //checked = {!reverse_charge_disabled}
            />
        </td>
        <td className={styles.itemstd1}>
                { filteredVatClassification?.length > 0 ?
                <AutocompleteSelect
                    name={vat_classification_name}
                    emptyOption={values.items[index].helperItem===true}
                    helperItem={values.items[index].helperItem}
                    data={filteredVatClassification}
                    disabled={!editable}
                    notNull={false}
                    itemRow={true}
                    selectedValue={values.items[index]["vat_classification"]} 
                        onChange={(newValue) => {
                            values.items[index]["vat_classification"]
                            vatClassificationChange(newValue)
                            setRefresh(!refresh)
                        }} 
                   
                >
                </AutocompleteSelect> : null}

                { filteredVatKvClassification?.length > 0 ? 
                <AutocompleteSelect
                name={vat_kv_classification_name}
                emptyOption={values.items[index].helperItem===true}
                helperItem={values.items[index].helperItem}
                data={filteredVatKvClassification}
                disabled={!editable}
                notNull={false}
                itemRow={true}
                selectedValue={values.items[index]["vat_kv_classification"]} 
                    onChange={(newValue) => {
                        values.items[index]["vat_kv_classification"]
                        vatKvClassificationChange(newValue)
                        setRefresh(!refresh)
                    }} 
               
            >
            </AutocompleteSelect> : null }
        </td>
        <td className={styles.acrosstwocolumnsbottom1}>
        <AutocompleteSelect 
                    name = {centre_name}
                    data={filteredCentres}
                    itemRow={true}
                    notNull={true}
                    //noBlankOption={values.items[index].helperItem===true? false: true}
                    emptyOption={values.items[index].helperItem===true}
                    helperItem={values.items[index].helperItem}
                    disabled={!editable}
                    selectedValue={values.items[index]["centre"]} 
                        onChange={(newValue) => {
                            values.items[index]["centre"]
                            centreChange(newValue)
                            setRefresh(!refresh)
                        }} 
                >
            </AutocompleteSelect>
        </td>
        <td className={styles.itemstd1}>
            <AutocompleteSelect 
                    name = {activity_name}
                    data={filteredActivities}
                    itemRow={true}
                    notNull={true}
                    //noBlankOption={values.items[index].helperItem===true? false: true}
                    emptyOption={values.items[index].helperItem===true}
                    helperItem={values.items[index].helperItem}
                    disabled={!editable}
                    selectedValue={values.items[index]["activity"]} 
                        onChange={(newValue) => {
                            values.items[index]["activity"]
                            activityChange(newValue)
                            setRefresh(!refresh)
                        }} 
                >
            </AutocompleteSelect>
        </td>
        <td className={styles.itemstd1}>
            <AutocompleteSelect 
                    name = {contract_name}
                    data={filteredContracts}
                    itemRow={true}
                    notNull={true}
                    //noBlankOption={values.items[index].helperItem===true? false: true}
                    emptyOption={values.items[index].helperItem===true}
                    helperItem={values.items[index].helperItem}
                    disabled={!editable}
                    selectedValue={values.items[index]["contract"]} 
                        onChange={(newValue) => {
                            values.items[index]["contract"]
                            contractChange(newValue)
                            setRefresh(!refresh)
                        }} 
                >
            </AutocompleteSelect>
        </td>
        </tr>
        </tbody>
        </table>
        )}

        
        </FieldArray>
        </div>
        </>        
    )
};
