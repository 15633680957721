import React, { useRef } from 'react';
import Select from 'react-select';

const normalizeString = (str) => {
    if (typeof str !== 'string') {
        return '';
    }
    return str
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toLowerCase();
};

const filterOption = (option, inputValue) => {
    const normalizedLabel = normalizeString(option.label);
    const normalizedInput = normalizeString(inputValue);
    return normalizedLabel.includes(normalizedInput);
};

export const AutocompleteSelect = ({
    blank,
    field,
    firstOption,
    displayAttribute,
    itemRow,
    inputClass,
    notNull,
    noBlankOption,
    emptyOption,
    onlyvalid,
    selectedValue,
    disabled,
    helperItem,
    ...props
}) => {
    const menuWidth = 'auto';
    
    const selectRef = useRef(null);
    const controlRef = useRef(null);

    let options = [];

    if (helperItem) {
        options.push({ value: -2, label: " \u00A0 " });
    }
    if (!noBlankOption) {
        options.push({ value: undefined, label: "---" });
    }

    props.data.forEach(item => {
        const id = item?.id ?? item?.slug ?? item;
        const display = (item && item[displayAttribute || 'title']) || item || blank;
        options.push({ value: id, label: display });
    });

    const customStyles = {
        container: (provided) => ({
            ...provided,
            padding: 0,
            margin: 0,
            marginLeft: itemRow ? "-9pt" : "",
            marginRight: itemRow ? "-9pt" : "",
            height: itemRow ? '1rem' : '',
            overflow: 'hidden',
            width: itemRow ? 'calc(100% + 10pt)' : ""
        }),
        control: (provided, state) => ({
            ...provided,
            border: 'none',
            borderBottom: itemRow ? 0 : '1px solid var(--semilight)',
            boxShadow: 'none',
            '&:hover': {
                borderBottom: itemRow ? 0 : '1px solid var(--primary)',
            },
            fontFamily: 'arial',
            fontSize: itemRow ? 'var(--xs)' : 'var(--s)',
            fontWeight: 'normal',
            padding: 0,
            display: 'flex',
            alignItems: 'center',
            textAlign: 'left',
            backgroundColor: '',
            marginTop: itemRow ? "-6pt" :"",
            width: "100%"
        }),
        menu: (provided) => ({
            ...provided,
            width: menuWidth, 
            minWidth: '100%', 
            zIndex: 9999,
        }),
        singleValue: (provided) => ({
            ...provided,
            margin: 0,
            padding: 0,
            height: itemRow ? '100%' : '',
            fontFamily: 'arial',
            fontSize: itemRow ? 'var(--xs)' : 'var(--s)',
            fontWeight: 'normal',
            textAlign: 'left',
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            boxSizing: 'border-box',
        }),
        indicatorSeparator: (provided) => ({
            ...provided,
            display: 'none',
        }),
        dropdownIndicator: (provided, state) => ({
            ...provided,
            padding: 0,
            color: state.isDisabled ? 'gray' : 'var(--primary)',
            transform: itemRow ? 'scale(0.5)' : 'scale(0.9)',
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected
                ? 'var(--primarylight)'
                : state.isFocused
                ? 'rgba(0, 0, 0, 0.05)'
                : 'white',
            color: 'black',
            cursor: 'pointer',
            fontFamily: 'arial',
            fontSize: itemRow ? 'var(--xs)' : 'var(--s)',
            fontWeight: 'normal',
            paddingLeft: "3pt",
            paddingRight: "3pt",
            paddingTop: 0,
            paddingBottom: 0,
            lineHeight: '1.3',
            textAlign: 'left',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            ':active': {
                backgroundColor: 'var(--primarylight)',
                color: 'black',
            },
            ':focus': {
                outline: 'none',
            },
        }),
        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
    };

    return (
        <Select
            ref={selectRef}
            inputRef={controlRef}
            options={options}
            value={options.find(option => option.value === selectedValue)}
            isDisabled={disabled}
            onChange={(selectedOption) => {
                if (props.onChange) {
                    props.onChange(selectedOption?.value);
                }
            }}
            styles={customStyles}
            menuPortalTarget={document.body}
            placeholder={options.find(option => option.value === selectedValue) ? undefined : "---"}
            noOptionsMessage={() => 'Žádné'}
            filterOption={filterOption}
        />
    );
};
