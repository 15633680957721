import React, {useEffect, useState} from 'react';
import {Redirect, Route, Switch, useHistory, useLocation, useParams} from 'react-router-dom';
import {useFormikContext, Form} from 'formik';

import {useToast,useModal,useFetch} from 'helpers/hooks';
import {Accounting} from 'components/Accounting';
import {Button} from 'components/Button';
import {Costs} from 'components/Costs';
import {General} from 'components/General';
import {HistoryTable} from 'components/HistoryTable';
import {MetadataTable} from 'components/MetadataTable';
import {Payment} from 'components/Payment';
import {Supplier} from 'components/Supplier';
import styles from 'css_modules/EditForm.module.css';
import {nullifyEmptyValues, stringifyNullValues, request} from "../helpers/tools";
import {InfoModal} from "./InfoModal";
import {LoadTemplateModal} from "./LoadTemplateModal";

export const EditForm = (
    {
        innerRef,
           accountCode, accountingYears, accounts, activities, centres, clientCompanies, contracts, countries, currencies,
        detail, doc, docTypes, isAccountant, isCashDeskDocType, numSeries, paymentTypes, permissions, requiredFields,
        taxRates, vatClassification, vatKvClassification, user, setDuplicates, update, focusedDocId, setFocusedDocId,docCustomType,setDocCustomType
    }
    ) =>
        {
        const excludedKeys = [ 
            //"accounting_year",
            //"document_identifier",
            "state",
            "number",
            "date",
            "due_date",
            "accounting_date",
            "variable_symbol",
            "taxable_supply_date",
            "tax_deduction_date",
            "payment_date",
            "version_id",
            "document_id",
            "id",
            "template_name"]        
        
        
        const checkCurrencyChanges = ()=>{ // if currency was set before, don't auto set euros for slovaks
            let flag = false;
            const log = detail.history;

            log.forEach(el => {
                if(Array.isArray(el.data)){
                    if(flag){ return flag;}

                    el.data.forEach(subel => {
                        if(subel[0] === 'Měna'){
                            flag = true;
                            return true;
                        }
                    })

                }
            });
            return flag;
        }
        
        const [loadTemplateModal, toggleLoadTemplateModal] = useModal();
        const [infomodal, toggleinfomodal] = useModal();

        const [handlySetCurrency, setHandlySetCurrency] = useState(checkCurrencyChanges);
        const [noteChanged, setNoteChanged] = useState(false);
        const [submitCheck, setSubmitCheck] = useState(0);
        const [initCheck, setInitCheck] = useState(0);
        const [steps, setSteps] =
            useState(['general', 'supplier', 'payment', 'costs', 'accounting', 'history', 'metadata']);
        const canTemplate = user.toggle_document_templates
        const history = useHistory();
        const location = useLocation();
        const {table} = useParams();
        const [accountingYear, setAccountingYear] = useState(0);
        const [currentCountry, setCurrentCountry] = useState("");

        const {errors, submitCount, values,validateForm} = useFormikContext(); //isSubmitting,
        if (initCheck===0){
            validateForm()
            setInitCheck(1)
        }
        
        let [docTypeToSave, setdocTypeToSave] = useState('');
        function handleCustomTypeSave (type){
            setdocTypeToSave (type)
        }
        

        let [saveButtonTitle, setSaveButtonTitle] = useState('Uložit');
        let [saving, setSaving] = useState(false);
        let [differencesFromTemplateLoad, setdifferencesFromTemplateLoad] = useState([]);

        

        const templates = useFetch(`/my_templates/?search=&active_only=false&page=1&limit=200`, null)
        

        const showToast = useToast();

        useEffect(() => { // remove accounting from steps for arrow navigation if user is not accountant
            setSteps(steps => [...steps.slice(0, 4), isAccountant ? 'accounting' : null, ...steps.slice(5)])
        }, [isAccountant, setSteps]);

        useEffect(() => { // remove payment from steps for arrow navigation if document type does not require it
            setSteps(steps =>
                [...steps.slice(0, 2), steps[2] = isCashDeskDocType ? null : 'payment', ...steps.slice(3)])
        }, [isCashDeskDocType, setSteps]);

        useEffect(() => { // navigate between steps with arrows
            const lastNonTrailingSlash = (location.pathname.slice(0, -1).lastIndexOf('/'));
            const currentStep = (location.pathname.slice(lastNonTrailingSlash + 1));
            const baseUrl = location.pathname.slice(0, lastNonTrailingSlash + 1);
            const currentSteps = steps.filter(step => step != null);
            const currentStepIndex = currentSteps.indexOf(currentStep);
            const handleKeyDown = event => {
                if (document.activeElement.tagName !== 'INPUT') {
                    if (event.key === 'ArrowRight' && currentStepIndex < currentSteps.length - 1) {
                        history.push(baseUrl + (currentSteps[currentStepIndex + 1]))
                    } else if (event.key === 'ArrowLeft' && currentStepIndex > 0) {
                        history.push(baseUrl + (currentSteps[currentStepIndex - 1]))
                    }
                }
            };
            window.addEventListener('keydown', handleKeyDown);
            return () => window.removeEventListener('keydown', handleKeyDown);
        }, [location, history, steps]);

        useEffect(() => {
            if (submitCount > submitCheck) {
                if (errors.info) {
                    //toggleinfomodal()
                    //showToast('Opravte prosím chyby ve formuláři');
                    setSubmitCheck(submitCount);
                }
                if (errors.costs) {
                    showToast('Náklady obsahují údaje v nesprávném formátu');
                    setSubmitCheck(submitCount);
                }
            }
        }, [errors.costs, errors.info, showToast, submitCheck, submitCount]);

        const editable = (doc.is_active && !doc.awaiting_capture && permissions.edit)||  location.pathname.split("/")[1]==="my_templates";
        

        const cancelButtonText = editable ? 'Zrušit editaci' : 'Zavřít';
        let loadTemplateText = 'Načíst ze šablony'

        const handleCancelButtonClick = () => {
            history.push(`/${table}`, {focusedDocId})
        };

        function handleLoadTemplate  () {
            toggleLoadTemplateModal()
        }

        const preProcessCosts = (costs) => {
            let processed_costs = [];
            costs.forEach((c)=>{
                let itemsassigned = sessionStorage.getItem("itemsassigned"+c.id)
                
                if( //cost is non zero or cost has items that add up to zero
                    (c.value_original !== '' && c.value_original && parseFloat(c.value_original)!==0) ||
                    (parseInt(itemsassigned) && parseInt(itemsassigned)>0) ) {
                        if (c.tax_rate && parseInt(c.tax_rate) ){
                            processed_costs.push(c);
                        }
                }
                else{
                    //console.log('invalid cost')
                }
            })
            return processed_costs;
       
        }

        const preProcessItems = (values) => {
            let processed_items = [];
            
            values.items?.forEach((i)=>{
                if (!i["centre"]){i["centre"] = values.info["centre"]}
                if (!i["vat_classification"]){i["vat_classification"] = values.info["vat_classification"]}
                if (!i["vat_kv_classification"]){i["vat_kv_classification"] = values.info["vat_kv_classification"]}
                if (!i["reverse_charge"]){i["reverse_charge"] = values.info["reverse_charge"]}
                if (!i["contract"]){i["contract"] = values.info["contract"]}
                if (!i["activity"]){i["activity"] = values.info["activity"]}
                if (!i["account_code"]){i["account_code"] = values.info["account_code"]}
                if (!i["expiration_date"]){i["expiration_date"] = null}

                if (!i["cost"] || !i["cost"]===''){
                    i["id"] = undefined
                }

                if (i.text !== '' && i.text.length>80){
                    i.text = i.text.substring(0, 76) + '...'
                }
                if(i.text === ''){i.text = 'Položka '+ (processed_items.length+1);}


                if(i.price_vat!=='' && i.price_original!=='' && i.price_unit!=='') { //everything filled in
                    
                    if (i.quantity===""){i.quantity = 1}
                    
                    i.price_vat = parseFloat(i.price_vat).toFixed(2)
                    i.price_original = parseFloat(i.price_original).toFixed(2)
                    i.price_final = parseFloat(parseFloat(i.price_original).toFixed(2)+parseFloat(i.price_vat).toFixed(2)).toFixed(2)
                    i.price_unit = parseFloat(i.price_unit).toFixed(2)//parseFloat(i.price_original/parseFloat(i.quantity)).toFixed(2)
                    
                    processed_items.push(i);
                }
                
            })
            return processed_items;
        }


        const sumAssignedItems = (cost) => {
            let sumOriginal = 0;
            values.items?.filter( item => parseInt(item.tax_rate) === parseInt(cost.tax_rate)).forEach(item=> sumOriginal += parseFloat(item.price_original) )
            sumOriginal = parseFloat(sumOriginal)

            const taxrate = parseFloat( taxRates?.find(rate => rate.id === parseInt(cost.tax_rate))?.rate);
            cost.value_original = sumOriginal;
            cost.tax = sumOriginal * taxrate/100;
            cost.value_final = sumOriginal + sumOriginal * taxrate/100
        }

        const handleUnfilledCosts = () => {
            values.costs?.filter(cost => cost.value_final==='').forEach(cost =>  sumAssignedItems(cost))
        }

        
        const getSimilarKeysInDicts = (databaseDict, values) =>{
            let diffs = []
            const ignoredKeys = ["tax_rate"]
            for (var i = 0; i < Object.keys(databaseDict).length; i++) {
                let key = Object.keys(databaseDict)[i]
                
                if (key in values && key in databaseDict && !ignoredKeys.includes(key)){
                    let val1 = databaseDict[key]
                    let val2 = values[key]

                    if (val1 === null || val1 === undefined){val1 = ''}
                    if (val2 === null || val2 === undefined){val2 = ''}
                    
                    
                    if (val1 !== val2){
                        if (!['accounting_year'].includes(key)){
                            ///console.log('DIFFERENCE in ' + key +' init:' + val1 + ' curr  ' + val2)    
                            diffs.push(key)
                        }
                    }
                    else{
                        //console.log('OK '  + key +' ' + val1 + '  ' + val2)    
                    }
                }
                else{
                    ///console.log(key+'  not present in both')
                }
            }
           
            return diffs
        }
        
        
        const objectsEqual = (values,formInitValues) => {
            let differences = differencesFromTemplateLoad
            let initVals = formInitValues
            
            differences = getSimilarKeysInDicts({...initVals["info"]}, {...values.info})
            
            differencesFromTemplateLoad.forEach(item => {
                differences.push(item);
            });
            
            let d_costs = false
            if ([...initVals["costs"]].length === [...values.costs].length ){
                for(let i =0; i<[...values.costs].length; i++){
                        if (getSimilarKeysInDicts([...initVals["costs"]][i], [...values.costs][i]).length>0){
                           d_costs = true
                        }
                }
            }
            else{d_costs = true}
           
            let d_items = false
            if ([...initVals["items"]].length === [...values.items].length ){
                for(let i =0; i<[...values.items].length; i++){
                        if (getSimilarKeysInDicts([...initVals["items"]][i], [...values.items][i]).length>0){
                            d_items = true
                        }
                }
            }
            else{d_items = true}
            //if (d_items===true){d_costs = true}
            
            if (d_costs===true){differences.push("costs")}
            if (d_items===true){differences.push("items")}

            return  Array.from(new Set(differences));
        }

        const isSaveNeeded = (formInitValues) => {
            let differences = objectsEqual(values,formInitValues)
            if (sessionStorage.getItem("shouldCheckIsSaveNeeded") === "false"){
                sessionStorage.setItem("shouldCheckIsSaveNeeded","true") 
                return differences = []
            }

            let valuesunchanged = differences.length === 0 ? true : false
            
            if (valuesunchanged === false){
                sessionStorage.setItem("saverequired", "1");
                sessionStorage.setItem("differences", JSON.stringify(differences));
            }
            else{
                sessionStorage.setItem("saverequired", "0");
                sessionStorage.setItem("differences", "");
            } 

            return differences
        }
    
        const saveButtonClickWrapper = async () => {
            sessionStorage.setItem("stateforduplicatemodal", "saving");
            onSaveButtonClick()
        }

        const correctNullValue = (val) =>{
            if (val !== "" && (val===null || val===undefined)){
                console.log('Tried to save a Null value!')
                val=""
            }
        }

        const correctNullValues = () =>{
            correctNullValue(values.info.centre)
            correctNullValue(values.info.activity)
            correctNullValue(values.info.contract)
        }

        
        const onSaveButtonClick = async ()=>{
            let reload = true
            
            if (String(sessionStorage.getItem('reloadaftersave'))==='false'){
                reload = false
            }
            console.log('Saving doc, reload ' + reload)
            correctNullValues()
            
            validateForm()
            if (errors.info && errors.info["unknown_rate"]){
                delete errors.info["unknown_rate"]
            }
            if (errors.info && errors.info["supplier_title"]){
                if (values.info.supplier_title !== undefined && values.info.supplier_title !== ""){
                    delete errors.info["supplier_title"]
                }
            }

            if (values.info.document_identifier !== '' &&
                values.info.document_identifier.length>80){
                values.info.document_identifier = values.info.document_identifier.substring(0, 76) + '...'
            }

            if (values.info.supplier_city !== '' &&
                values.info.supplier_city.length>40){
                values.info.supplier_city = values.info.supplier_city.substring(0, 40)
            }
            if (values.info.supplier_street !== '' &&
                values.info.supplier_street.length>60){
                values.info.supplier_street = values.info.supplier_street.substring(0, 60)
            }
            if (values.info.supplier_zip_code !== '' &&
                values.info.supplier_zip_code.length>9){
                values.info.supplier_zip_code = values.info.supplier_zip_code.substring(0, 9)
            }
            if (values.info.supplier_title !== '' &&
                values.info.supplier_title.length>250){
                values.info.supplier_title = values.info.supplier_title.substring(0, 250)
            }

            const companyIdToSearch = parseInt(values.info["client_company"])
            let countrySlug = ''
            if (companyIdToSearch){
            const foundCompany = clientCompanies.find(company => parseInt(company.id) === parseInt(companyIdToSearch));
            countrySlug = foundCompany ? foundCompany.country.slug : null;
            }
            if (countrySlug!=='sk'){
                values.info.supplier_sk_ic_dph=""
            }
            

            /*
            if (values.info.supplier_country!=="sk"){
                values.info.supplier_sk_ic_dph=""
            }*/

            let uuid = doc.uuid;
            
            setSaveButtonTitle('Ukládám...')
            setSaving(true)
            
            handleUnfilledCosts();
            
            let customType = -1
            if (parseInt(docTypeToSave)){
                customType = parseInt(docTypeToSave)
                values.info.document_type = 'rzn'
            }                      
            
            if (preProcessCosts(values.costs).length===0){
                values.info.rounding = 0
            }

            let outputData = {
                ...values,
                costs: nullifyEmptyValues(preProcessCosts(values.costs)),
                items: stringifyNullValues(preProcessItems(values))
            }

            if (outputData.info.reverse_charge === '' ){ outputData.info.reverse_charge  = false; }
            
            for (let i = 0; i < outputData.items.length; i++){
                if (outputData.items[i].guarantee === '' ){ outputData.items[i].guarantee = 0; }
                if (outputData.items[i].reverse_charge === '' ){ outputData.items[i].reverse_charge = false; }
                if (outputData.items[i].expiration_date === '' ){ outputData.items[i].expiration_date = null; }
            }

            let abortSave = false
            for (let i = 0; i<outputData.costs.length; i++){
                if (taxRates?.find(rate => rate.id === parseInt(outputData.costs[i].tax_rate))?.slug.endsWith('unknown') ){
                     if (!errors.info){
                        errors.info = {}
                    }
                    errors.info["unknown_rate"] = "Neznámá daňová sazba"
                    break;
                }
            }

            //console.log("Checking for errors? " + sessionStorage.getItem("checkerrors"))
            if ( sessionStorage.getItem("checkerrors") === "true"){
                if (errors.info &&  Object.keys(errors.info).length>0){
                    console.log('Errors:')
                    console.log(errors.info)
                    console.log(Object.keys(errors.info).length)
                    toggleinfomodal()
                    sessionStorage.setItem("docerrors", "errors");
                    setSaveButtonTitle('Uložit')
                    setSaving(false)
                    abortSave=true
                }
                sessionStorage.setItem("checkerrors", "false") 
            }
          
                        
            if (!abortSave){
                let url = `/detail/${uuid}/`
                if (sessionStorage.getItem("stateforduplicatemodal")==="forcesave"){
                    url= `/detail/${uuid}/?force=true`
                }
                
                const response = await request(url, 'PUT', outputData);
                if (response){
                    setSaveButtonTitle('Uložit')
                    setSaving(false)
                    //console.log(response)
                    //console.log(sessionStorage.getItem("forceMove"))
                }
                
                
                if ( ([null,""].includes(sessionStorage.getItem("forceMove")) || !sessionStorage.getItem("forceMove") === "0") && response.status === 403 && response.body.length>0) {
                    //console.log("duplicates")
                    if (!(sessionStorage.getItem("stateforduplicatemodal")==="forwarding")&& !(sessionStorage.getItem("stateforduplicatemodal")==="forcesave")){
                        setDuplicates(response.body)
                        abortSave=true
                        sessionStorage.setItem("saved", "duplicates");
                        sessionStorage.setItem("stateforduplicatemodal", "")
                    }
                    else{
                        sessionStorage.setItem("stateforduplicatemodal", "")
                        sessionStorage.setItem("duplicateuuids", JSON.stringify(response.body))
                        return response.body
                    }
                }
                else if (response.ok){
                    showToast('Dokument byl uložen.');
                    setNoteChanged(false);
                    update();
                    sessionStorage.setItem("saved", "ok");
                    if (!(sessionStorage.getItem("stateforduplicatemodal")==="forcesave") ){
                        if (reload===true){
                            //console.log('reloading after successfull save')
                            window.location.reload();
                        }
                    }
                    
                    if (customType>=0){
                        request('/set_user_type_for_documents/', 'POST', {"documents":String(uuid),"user_type": customType});
                    }
                    else{
                        request('/set_user_type_for_documents/', 'POST', {"documents":String(uuid),"user_type": ''});
                    }
                }  
            }
        }

        const shouldDisplaySaveButton = () =>
            (noteChanged && permissions.edit) || editable;

              
        innerRef.current={
            isSaveNeeded : isSaveNeeded,
            onSaveButtonClick : onSaveButtonClick
        }
    
    /*
    const searchForDataByYear = (id, year, key) => {
        let matchingData = ""
        if (String(key) === 'activity'){
            const activity = activities.find((item) => String(item.id) === String(id))
            if (activity){
                let title = activity.title
                matchingData = activities.find((item) => String(item.title) === String(title) && String(item.year) === String(year));
            }
        }
        if (String(key) === 'centre'){
            const centre = centres.find((item) => String(item.id) === String(id))
            if (centre){
                let title = centre.title
                matchingData = centres.find((item) => String(item.title) === String(title) && String(item.year) === String(year));
            }
        }
        if (String(key) === 'contract'){
            const contract  = contracts.find((item) => String(item.id) === String(id))
            if (contract){
                let title = contract.title
                matchingData = contracts.find((item) => String(item.title) === String(title) && String(item.year) === String(year));
            }
        }
        return matchingData? matchingData.id : ""
    };
    */
    
    /*
    const yearDependentData = [
        "accounting_year",
        "centre",
        "activity",
        "contract",
        "num_series",
        "vat_classification",
        "vat_kv_classification",
        "account_code"
    ];
    const checkYearDependentDataSet = () => {
        //Check whether among the elements are not any of year dependent data set
        const checkKeys = [
            "centre",
            "activity",
            "contract",
            "num_series",
            "vat_classification",
            "vat_kv_classification",
            "account_code"
        ];
         for (const item of values.items) {
            for (const property of checkKeys) {
              if (item[property] !== "" && item[property] !== undefined && item[property] !== null) {
                console.log("item " + property + " already set")
                return true; 
              }
            }
          }
          
        for (const property of checkKeys) {
            if (values.info[property] !== "" && values.info[property] !== undefined && values.info[property] !== null) {
            console.log("info " + property + " already set")
            return true; 
            }
        }
        
        console.log("year dependent data not set")
        return false; 
    };*/

    const ccDependentKeys = [ 
        "client_company",
        "centre",
        "activity",
        "contract",
        "num_series",
        "vat_classification",
        "vat_kv_classification",
        "account_code"]     
    
    const overwriteKeys = ["document_type", "accounting_year", "reverse_charge"]
    
    let currentCompany = clientCompanies.find(company => parseInt(company.id) === parseInt(values.info["client_company"]));
    let slug = currentCompany ? currentCompany.country.slug : null;
    if (currentCountry!== slug){setCurrentCountry(slug)}

    
    const loadFromTemplate = (overwrite) =>{
        let ignoreYear = !clientCompanies.find(cc => cc.id === values.info["client_company"])

        if (overwrite === true){
            console.log("\n overwritting from template")
        }
        if (overwrite === false){
            console.log("\n filling from template")
        }
        
        let dtemplate = JSON.parse(sessionStorage.getItem("template")) 
        let oldValues = JSON.parse(JSON.stringify(values) )
        
        const countWithNumericOriginal = values.costs.filter(obj => obj.value_original !== "" && obj.value_original !== null && obj.value_original !== undefined).length;

        let changedCC = values.info["client_company"] !== dtemplate.info["client_company"];
        let changedCountry = true
        if (clientCompanies.find(cc => cc.id === values.info["client_company"]) && clientCompanies.find(cc => cc.id === dtemplate.info["client_company"])){
            changedCountry = clientCompanies.find(cc => cc.id === values.info["client_company"]).country.title !== clientCompanies.find(cc => cc.id === dtemplate.info["client_company"]).country.title
        }
        else if (clientCompanies.find(cc => cc.id === values.info["client_company"])){
            changedCountry = clientCompanies.find(cc => cc.id === values.info["client_company"]).country.slug!=="cz"
            console.log(clientCompanies.find(cc => cc.id === values.info["client_company"]).country.slug)
            console.log(clientCompanies.find(cc => cc.id === values.info["client_company"]).country.slug!=="cz")
        }
        else if(!clientCompanies.find(cc => cc.id === values.info["client_company"]) && !clientCompanies.find(cc => cc.id === dtemplate.info["client_company"])){
            changedCountry = false
        }

        console.log("Changed CC ? " ,changedCC)
        console.log("Ignore year ? " ,ignoreYear)
        console.log("Changed Country ? " ,changedCountry)

        Object.keys(values.info).forEach((key) => {
            if ( !excludedKeys.includes(key) ) {
                    if (overwrite === true){
                        if (dtemplate.info[key]){
                            console.log("overwriting " +key + " from " + values.info[key] + " -> " +dtemplate.info[key])
                            values.info[key] = dtemplate.info[key];
                        }
                    }
                    if (overwrite === false){
                        if (!values.info[key] || overwriteKeys.includes(key) ){
                            if ( !(ccDependentKeys.includes(key) && changedCC===true) || ignoreYear===true ){
                                if (dtemplate.info[key]){
                                    console.log("filling " +key + " from " + values.info[key] + " -> " +dtemplate.info[key])
                                    values.info[key] = dtemplate.info[key];
                                }
                            }
                            else{
                                console.log("keeping " +key + " " + values.info[key] + " because CC cannot change")                
                            }
                        }
                    }

                    if (!values.info[key]) {
                        values.info[key] = "";
                    }
            }
            else{
                console.log("keeping " +key + " " + values.info[key] + " because is excluded")
            }
        });
        values.info["template_name"]=""

        let currentCompany = clientCompanies.find(company => parseInt(company.id) === parseInt(values.info["client_company"]));
        let slug = currentCompany ? currentCompany.country.slug : null;
        if (currentCountry!== slug){setCurrentCountry(slug)}
        
        
        if ( (changedCountry===false) && ((countWithNumericOriginal===0) || overwrite === true)){
            console.log("inserting costs")
            values.costs=dtemplate.costs
            values.costs.forEach(i=>{
                i["id"]=undefined
                i["document_id"]=undefined
            })
            console.log("costs")
            console.log(values.costs)

            values.items=[]
            if (dtemplate.items && dtemplate.items.length>0){
                dtemplate.items.forEach(i=>{
                    i["id"]=undefined
                    i["cost_id"]=undefined
                    i["external_id"]=undefined
                    i["document_id"]=undefined
                    if (changedCC===true){
                        ccDependentKeys.forEach(k => {
                            i[k] = undefined
                        })
                    }
                    values.items.push(i)
                })
            }
            console.log("items")
            console.log(values.items)
        }
        if (changedCountry===true){
            showToast("Náklady nebylo možné přenést.")
        }
    
        


        let diffs = objectsEqual(values,oldValues)
        setdifferencesFromTemplateLoad(diffs)
    }

    let accYear =  accountingYears?.find(y => y.id === parseInt(values.info["accounting_year"]))
    if (accYear && accountingYear !== accYear.year){
        setAccountingYear(accYear.year)
    }
   
    
    return (
        <>
            <Form>
                <Switch>
                    <Route exact path={`/${table}/:uuid/general`}>
                        <General
                            docTypes={docTypes}
                            clientCompanies={clientCompanies}
                            accountingYears={accountingYears}
                            activities={activities}
                            centres={centres}
                            contracts={contracts}
                            editable={editable}
                            doc={doc}
                            isAccountant={isAccountant}
                            isCashDeskDocType={isCashDeskDocType}
                            fetchRequiredFields={requiredFields}
                            noteEditable={permissions.edit}
                            setNoteChanged={setNoteChanged}
                            user={user}
                            handleCustomTypeSave={handleCustomTypeSave}
                            docCustomType={docCustomType}
                            setDocCustomType={setDocCustomType}
                            excludedKeys={excludedKeys}
                            setAccountingYear={setAccountingYear}
                            taxRates={taxRates} 
                            countries={countries}
                        />
                    </Route>
                    <Route exact path={`/${table}/:uuid/supplier`}>
                        <Supplier
                            user={user} 
                            countries={countries}
                            editable={editable}
                            isAccountant={isAccountant}
                            fetchRequiredFields={requiredFields}
                            clientCompanies={clientCompanies} 
                            excludedKeys={excludedKeys}
                        />
                    </Route>
                    <Route exact path={`/${table}/:uuid/payment`}>
                        {isCashDeskDocType
                            ? <Redirect to={`/${table}/${doc.uuid}}/general`}/>
                            : <Payment
                                user={user} 
                                paymentTypes={paymentTypes}
                                accounts={accounts}
                                editable={editable}
                                isAccountant={isAccountant}
                                fetchRequiredFields={requiredFields}
                                excludedKeys={excludedKeys}
                            />
                        }
                    </Route>
                    <Route exact path={`/${table}/:uuid/costs`}>
                        <Costs  taxRates={taxRates} 
                                user={user} 
                                handlySetCurrency={handlySetCurrency} 
                                setHandlySetCurrency={setHandlySetCurrency} 
                                currencies={currencies} 
                                editable={editable} 
                                isAccountant={isAccountant} 
                                log = {detail.history} 
                                docTypes={docTypes}
                                doc={doc}
                                accountingYears={accountingYears}
                                clientCompanies={clientCompanies} 
                                centres ={centres}
                                activities={activities}
                                contracts={contracts}
                                vatClassification={vatClassification}
                                vatKvClassification={vatKvClassification}
                                accountCode={accountCode}
                                excludedKeys={excludedKeys}
                                />
                    </Route>
                    <Route exact path={`/${table}/:uuid/accounting`}>
                        {isAccountant ?
                            (
                                <Accounting
                                    user={user} 
                                    editable={editable}
                                    numSeries={numSeries}
                                    accountCode={accountCode}
                                    vatClassification={vatClassification}
                                    vatKvClassification={vatKvClassification}
                                    accountingYears={accountingYears}
                                    docTypes={docTypes}
                                    fetchRequiredFields={requiredFields}
                                    excludedKeys={excludedKeys}
                                />
                            ) : <Redirect to={`/${table}/${doc.uuid}}/general`}/>
                        }
                    </Route>
                    <Route exact path={`/${table}/:uuid/history`}>
                        <HistoryTable logs={detail.history}/>
                    </Route>
                    <Route exact path={`/${table}/:uuid/metadata`}>
                        <MetadataTable
                            doc={doc}
                            docTypes={docTypes}
                            clientCompanies={clientCompanies}
                            currencies={currencies}
                            detail={detail}
                            docState={doc.state}
                            externalId={doc.external_id}
                        />
                    </Route>
                </Switch>
                <div className={styles.buttons}>
                    <Button onClick={handleCancelButtonClick}>{cancelButtonText}</Button>
                    {(editable && location.pathname.includes("my_documents") && canTemplate) && <Button disabled={!templates.data}  onClick={handleLoadTemplate}>{loadTemplateText}</Button>}
                    {shouldDisplaySaveButton() && <Button onClick={saveButtonClickWrapper} primary disabled={saving} > {saveButtonTitle} </Button>}
                </div>
                
            </Form>

            {infomodal &&<InfoModal close={toggleinfomodal} errors={errors}/>}
            {(loadTemplateModal) &&<LoadTemplateModal close={toggleLoadTemplateModal} loadFromTemplate={loadFromTemplate} templates={templates} accountingYears={accountingYears}  ignoreYear={!clientCompanies.find(cc => cc.id === values.info["client_company"])} year={accountingYear} user={user}/>}
        </>
        )
    }
;